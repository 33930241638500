import { getTallyToken } from "@lib/tally/get-token";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import type { TallyShowAfterDate } from "@lib/tally/useTallyForm";
import { getAnalyticsTracker } from "@lib/analytics-tracker";

declare global {
  interface Window {
    Tally?: {
      openPopup: (formId: string, options: unknown) => void;
      closePopup: (formId: string) => void;
    };
  }
}

export const TALLY_SHOW_AFTER_DATE_KEY_NPS = "tally-show-after-date";

const TWO_MONTHS_IN_DAYS = 30 * 2;
const FIVE_MONTHS_IN_DAYS = 30 * 5;

/**
 * Display a popup to the user to submit an NPS score, when:
 * - The current page is the homepage
 * - The user is logged in
 * - If the Tally script has been loaded
 * - If the Tally form id has been set
 * - When the current date is after the date we stored on local storage
 */
export const useNps = ({
  enabled,
  formId,
  userId,
  organizationId,
  language,
  setShowAfterDate,
}: {
  enabled: boolean;
  formId?: string;
  userId?: string;
  organizationId: string;
  language: string;
  setShowAfterDate: (
    update: (showAfterDate: TallyShowAfterDate | undefined) => Record<string, string>,
  ) => void;
}) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const tracker = getAnalyticsTracker();

  const { data: tallyToken } = useQuery({
    queryKey: ["tallyToken"],
    queryFn: getTallyToken,
    enabled,
  });

  useEffect(() => {
    if (!tallyToken || !formId || !userId) return;

    if (enabled) {
      window.Tally?.openPopup(formId, {
        hiddenFields: {
          userId,
          organizationId,
          language,
          token: tallyToken,
          surveyId: `pep-nps-${formId}`,
        },
        emoji: {
          text: "👋",
          animation: "wave",
        },
        onOpen: () => {
          tracker?.elementClicked({
            elementId: "tally-nps-open",
            type: "auto-open",
            module: "tally-forms",
            itemId: "tally-nps-open",
          });
        },
        onClose: () => {
          if (hasBeenSubmitted) return;

          tracker?.elementClicked({
            elementId: "tally-nps-close",
            type: "button",
            module: "tally-forms",
            itemId: "tally-nps-close",
          });

          const nowMinusDaysToShowAfter = new Date();
          nowMinusDaysToShowAfter.setDate(nowMinusDaysToShowAfter.getDate() + TWO_MONTHS_IN_DAYS);
          setShowAfterDate((value) => ({
            ...(value || {}),
            [userId]: nowMinusDaysToShowAfter.toString(),
          }));
        },
        onSubmit: () => {
          tracker?.elementClicked({
            elementId: "tally-nps-submit",
            type: "button",
            module: "tally-forms",
            itemId: "tally-nps-submit",
          });

          const nowMinusDaysToShowAfter = new Date();
          nowMinusDaysToShowAfter.setDate(nowMinusDaysToShowAfter.getDate() + FIVE_MONTHS_IN_DAYS);
          setHasBeenSubmitted(true);
          setShowAfterDate((value) => ({
            ...(value || {}),
            [userId]: nowMinusDaysToShowAfter.toString(),
          }));
        },
      });
    } else {
      window.Tally?.closePopup(formId);
    }
  }, [
    tallyToken,
    formId,
    language,
    userId,
    hasBeenSubmitted,
    organizationId,
    setShowAfterDate,
    enabled,
    tracker,
  ]);
};
