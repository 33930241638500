import { getTallyToken } from "@lib/tally/get-token";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import type { TallyShownInfo } from "@lib/tally/useTallyForm";
import { getAnalyticsTracker } from "@lib/analytics-tracker";

export const TALLY_SHOWN_INFO_KEY_NEW_MEASUREMENTS = "tally-shown-info";

// RULES here => https://www.notion.so/infinitaslearning/Set-up-targeting-data-points-General-In-platform-Measurement-a0bc3ec8f44945678130df442c971850?pvs=4
export const useNewMeasurements = ({
  enabled,
  formId,
  userId,
  organizationId,
  language,
  setTallyShownInfo,
}: {
  enabled: boolean;
  formId?: string;
  userId?: string;
  organizationId: string;
  language: string;
  setTallyShownInfo: (
    update: (tallyShownInfo: TallyShownInfo | undefined) => TallyShownInfo,
  ) => void;
}) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const tracker = getAnalyticsTracker();

  const { data: tallyToken } = useQuery({
    queryKey: ["tallyToken-new-measurements"],
    queryFn: getTallyToken,
    enabled,
  });

  useEffect(() => {
    if (!tallyToken || !formId || !userId) return;

    if (enabled) {
      window.Tally?.openPopup(formId, {
        hiddenFields: {
          userId,
          organizationId,
          language,
          token: tallyToken,
          surveyId: `pep-new-measurements-${formId}`,
        },
        emoji: {
          text: "👋",
          animation: "wave",
        },
        onOpen: () => {
          tracker?.elementClicked({
            elementId: "tally-csat-open",
            type: "auto-open",
            module: "tally-forms",
            itemId: "tally-csat-open",
          });
        },
        onClose: () => {
          if (hasBeenSubmitted) return;

          tracker?.elementClicked({
            elementId: "tally-csat-close",
            type: "button",
            module: "tally-forms",
            itemId: "tally-csat-close",
          });

          setTallyShownInfo((oldState) => ({
            ...(oldState || {}),
            [userId]: {
              shownOn: new Date().toString(),
              formId,
            },
          }));
        },
        onSubmit: () => {
          tracker?.elementClicked({
            elementId: "tally-csat-submit",
            type: "button",
            module: "tally-forms",
            itemId: "tally-csat-submit",
          });

          setHasBeenSubmitted(true);
          setTallyShownInfo((oldState) => ({
            ...(oldState || {}),
            [userId]: {
              shownOn: new Date().toString(),
              formId,
            },
          }));
        },
      });
    } else {
      window.Tally?.closePopup(formId);
    }
  }, [
    tallyToken,
    formId,
    language,
    userId,
    hasBeenSubmitted,
    organizationId,
    setTallyShownInfo,
    enabled,
    tracker,
  ]);
};
